footer {
  background-color: var(--primary-bg); /* Black background */
  padding: 80px 0;
  color: var(--text-color); /* Gold text */
}

footer .box {
  padding: 0 20px;
}

footer h1 {
  margin: 0;
  font-size: 25px;
  font-weight: 800;
  width: max-content;
  color: var(--highlight-color); /* Darker gold */
}

footer .logo {
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}

footer h2 {
  font-size: 20px;
  color: var(--text-color);
  margin-bottom: 20px;
  border-bottom: 2px solid var(--border-color); /* Gold underline */
  padding-bottom: 5px;
  display: inline-block;
}

footer .row {
  justify-content: center;
}

footer p {
  font-size: 16px;
  margin-bottom: 20px;
  opacity: 0.7;
  color: var(--highlight-color); /* Dark gold text */
}

footer ul {
  padding: 0;
}

footer li {
  margin-bottom: 10px;
  opacity: 0.7;
  color: var(--text-color);
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
}

footer li:hover {
  opacity: 1;
  color: var(--highlight-color); /* Darker gold on hover */
}
